import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDisableDuringHttpCallsModule  } from '@mt-ng2/disable-during-http-calls';
import { MtPayPalModule, IMtPayPalModuleConfig, MtPayPalModuleConfigToken } from '@mt-ng2/paypal';

import { KeyboardShortcutModule, KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';
import { CommonService } from './services/common.service';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { environment } from '@environment';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';

import { WysiwygModule } from '@mt-ng2/wysiwyg-control';
import { auditLogModuleConfig } from './configs/audit-log.config';
import { CreateUserComponent } from './create-user/create-user.component';
import { DeleteEntityCellComponent } from './delete-entity-cell-component/delete-entity-cell.component';
import { AdminPortalFeatureModule } from './feature.module';
import { MemberActionsComponent } from './member-actions/member-actions.component';
import { MiniDashboardListComponent } from './mini-dashboard-list/mini-dashboard-list.component';
import { ResetPasswordOverrideComponent } from './reset-password-override/reset-password-override.component';
import { AuditLogUserService } from './services/audit-log-user.service';
import { AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { ModalModule } from '@mt-ng2/modal-module';
import { NavModule } from '@mt-ng2/nav-module';
import { FlexiFormComponent } from '@mt-ng2/flexi-form';
import { BackdoorLoginComponent } from './login/backdoor-login.component';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

const paypalConfig: IMtPayPalModuleConfig = { clientId: environment.payPalClientId };
/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    declarations: [
        MiniDashboardListComponent,
        CreateUserComponent,
        ResetPasswordOverrideComponent,
        MemberActionsComponent,
        DeleteEntityCellComponent,
    ],
    exports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    ModalModule,
    MtDisableDuringHttpCallsModule,
    BackButtonModule,
    KeyboardShortcutModule,
    MiniDashboardListComponent,
    MemberActionsComponent,
    MtDocumentControlModule,
    EntityComponentsDocumentsModule,
    MtPayPalModule,
    WysiwygModule,
    // Material Modules for Datatable, List and Buttons
    MatTableModule,
    MatSortModule,
    MatListModule,
    MatButtonModule,
    NavModule,
    FlexiFormComponent
],
    imports: [
    AdminPortalFeatureModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    ModalModule,
    MtDisableDuringHttpCallsModule,
    BackButtonModule,
    KeyboardShortcutModule,
    MtDocumentControlModule,
    EntityComponentsDocumentsModule,
    MtPayPalModule,
    WysiwygModule,
    // Material Modules for Datatable, List and Buttons
    MatTableModule,
    MatSortModule,
    MatListModule,
    MatButtonModule,
    NavModule,
    FlexiFormComponent
],
})
export class AdminPortalSharedModule {
    static forRoot(): any {
        return {
            ngModule: AdminPortalSharedModule,
            providers: [
                KeyboardShortcutService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
                { provide: MtPayPalModuleConfigToken, useValue: paypalConfig },
            ],
        };
    }
}
