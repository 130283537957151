import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { AuthConfig, AuthService, ILoginResponse, OnLoginHandler } from '@mt-ng2/auth-module';

@Injectable({
    providedIn: 'root',
})
export class BackDoorService {
    private _http = inject(HttpClient);
    private environmentService = inject(EnvironmentService);
    private authService = inject(AuthService);
    private authConfig = inject(AuthConfig);

    login(username: string, password: string, bookingNumber?: string, applicantNumber?: string, mrn?: string): Observable<ILoginResponse> {
        return this._http
            .post<ILoginResponse>('/authUsers/token', {
                AuthClientID: this.environmentService.config.authClientId,
                AuthClientSecret: this.environmentService.config.authSecretVariable,
                UserName: username,
                Password: password,
                BookingNumber: bookingNumber,
                ApplicantNumber: applicantNumber,
                Mrn: mrn,
            })
            .pipe(
                switchMap((response) => {
                    this.handleLogin(response, true);
                    return [response];
                }),
            );
    }
    private handleLogin(response: ILoginResponse, remember: boolean): void {
        this.authService.saveToken(response, remember);
        const loginHandler: OnLoginHandler | undefined = this.authConfig.eventHandlers.onLogin;
        if (loginHandler) {
            loginHandler(response);
        }
    }
}
