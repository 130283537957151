<div class="login-box">
    <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
    <div class="login-box-body">
        <p class="login-box-msg">{{ config.messageOverrides.loginLabel }}</p>
        <div *ngIf="!config.hideRegularSignIn">
            <form *ngIf="backdoorLoginForm" [formGroup]="backdoorLoginForm" (submit)="onLogin()">
                <div class="form-group has-feedback" [class.has-error]="isTheControlInvalid('username', 'required')">
                    <input type="text" autofocus class="form-control" placeholder="Username" formControlName="username" />
                    <span class="fa fa-user form-control-feedback"></span>
                    <div *ngIf="isTheControlInvalid('username', 'required')" class="small errortext" [style.position]="'absolute'">
                        Username is required
                    </div>
                </div>
                <div class="form-group has-feedback" [class.has-error]="isTheControlInvalid('password', 'required')">
                    <input #Password type="password" autocomplete="off" class="form-control" placeholder="Password" formControlName="password" />
                    <span class="fa fa-lock form-control-feedback"></span>
                    <div *ngIf="isTheControlInvalid('password', 'required')" class="small errortext" [style.position]="'absolute'">
                        Password is required
                    </div>
                </div>
                <div class="alert alert-info" role="alert" style="margin-bottom: 15px">
                    Please provide your Mariner Reference Number (MRN) and either your Booking Number or Applicant Number to validate your login.
                </div>
                <div class="form-group has-feedback" [class.has-error]="isTheControlInvalid('bookingNumber', 'atLeastOneRequired')">
                    <input type="text" class="form-control" placeholder="Book/Card Number" formControlName="bookingNumber" />
                    <span class="fa fa-book form-control-feedback"></span>
                </div>
                <div class="form-group has-feedback" [class.has-error]="isTheControlInvalid('applicantNumber', 'atLeastOneRequired')">
                    <input type="text" class="form-control" placeholder="Applicant Number" formControlName="applicantNumber" />
                    <span class="fa fa-book form-control-feedback"></span>
                </div>
                <div *ngIf="isTheControlInvalid('bookingNumber', 'atLeastOneRequired')" class="small errortext" [style.position]="'absolute'">
                    Either Booking Number or Applicant Number is required.
                </div>
                <div class="form-group has-feedback" [class.has-error]="isTheControlInvalid('mrn', 'required')">
                    <input type="text" class="form-control" placeholder="Mariner Reference Number" formControlName="mrn" />
                    <span class="fa fa-book form-control-feedback"></span>
                    <div *ngIf="isTheControlInvalid('mrn', 'required')" class="small errortext" [style.position]="'absolute'">
                        Mariner Reference Number is required.
                    </div>
                </div>
                <div style="display: flex; justify-content: flex-end">
                    <button [disabled]="!backdoorLoginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block">
                        {{ config.messageOverrides.signInButtonText }}
                    </button>
                </div>
            </form>
            <br />
            <div class="alert alert-info" role="alert" style="margin-bottom: 15px">
                If you are having trouble with your Book, Applicant Number or MRN#, please email memberservice@amo-union.org
            </div>
            <ng-container *ngFor="let link of config.loginComponentLinks">
                <div style="padding-left: 15px">
                    <span style="cursor: pointer" [innerHtml]="link.linkHtml" [routerLink]="link.routerLinkPath"></span><br />
                </div>
            </ng-container>
            <ng-container>
                <div style="padding-left: 15px">
                    <b style="color: rgba(34, 34, 34, 1); font-family: Calibri, sans-serif; font-size: 14.6667px">
                        <span style="font-size: 13.5pt; color: rgba(255, 70, 0, 1)">AMO Members and Applicants</span>
                    </b>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(255, 70, 0, 1)">
                        : If this is your first time logging into the new AMO Member Portal, your existing credentials for the previous AMO
                        Dispatching site will not work. For security reasons, you will need to reset your credentials and re-register your online
                        account with AMO. Please&nbsp;
                    </span>
                    <b style="color: rgba(34, 34, 34, 1); font-family: Calibri, sans-serif; font-size: 14.6667px">
                        <u>
                            <span style="font-size: 13.5pt; color: rgba(35, 62, 112, 1); cursor: pointer" [routerLink]="'/createMember'">
                                CLICK HERE
                            </span>
                        </u>
                    </b>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(35, 62, 112, 1)"> &nbsp; </span>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(255, 70, 0, 1)"> to do so.&nbsp; </span>
                    <span style="font-family: Calibri, sans-serif; font-size: 13.5pt; color: rgba(0, 0, 0, 1)"> &nbsp; </span>
                    <br />
                </div>
            </ng-container>
        </div>

        <div class="text-center third-party-buttons">
            <div *ngIf="hasGoogleAuthConfig">
                <br />
                <mt-google-login [config]="config"></mt-google-login>
            </div>
            <div *ngIf="showMFA">
                <br />
                <mt-ms-login [config]="config"></mt-ms-login>
            </div>
            <div *ngIf="showSwitch">
                <br />
                <button class="btn btn-flat btn-success" (click)="switchLogins()">
                    {{ switchLabel }}
                </button>
            </div>
        </div>
    </div>
</div>
