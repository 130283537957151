import { Component, ErrorHandler, inject } from '@angular/core';
import { appPaths } from '../../default-routes/app-paths.library';
import { BackDoorService } from '@common/services/backdoor-login-service';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ILoginResponse, MtAuthGuard } from '@mt-ng2/auth-module';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthConfig } from '@mt-ng2/auth-module';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginConfig } from '@mt-ng2/login-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { throwError } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { environment } from '@environment';

interface IBackDoorLoginForm {
    password: FormControl<string | null>;
    username: FormControl<string | null>;
    bookingNumber: FormControl<string | null>;
    applicantNumber: FormControl<string | null>;
    mrn: FormControl<string | null>;
}

function atLeastOneRequired(control: AbstractControl): ValidationErrors | null {
    const bookingNumber = control.get('bookingNumber')?.value;
    const applicantNumber = control.get('applicantNumber')?.value;
    if (!bookingNumber && !applicantNumber) {
        return { atLeastOneRequired: true };
    }
    return null;
}

@Component({
    selector: 'app-backdoor-login',
    templateUrl: './backdoor-login.component.html',
})
export class BackdoorLoginComponent {
    logoFull = `${environment.assetsPath}logo-full.png`;
    showMFA = false;
    showSwitch = true;
    switchLabel = 'Switch to Admin Login';
    switchPath = appPaths.loginAdmin;
    errorMessage: string | null = null;
    backdoorLoginForm = new FormGroup<IBackDoorLoginForm>(
        {
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            bookingNumber: new FormControl(''),
            applicantNumber: new FormControl(''),
            mrn: new FormControl('', Validators.required),
        },
        { validators: atLeastOneRequired },
    );
    returnUrl: string | null = null;
    authConfig = inject(AuthConfig);
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);
    public config = inject(LoginConfig);
    private notificationsService = inject(NotificationsService);
    private globalErrorHandler = inject(ErrorHandler);

    private _backdoorService = inject(BackDoorService);
    get hasGoogleAuthConfig(): boolean {
        return this.config.googleAuthConfig ? true : false;
    }

    constructor() {
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }

    switchLogins(): void {
        void this.router.navigate([this.switchPath]);
    }
    onLogin(): void {
        if (this.backdoorLoginForm?.valid) {
            const values = this.backdoorLoginForm.value;
            this._backdoorService.login(values.username, values.password, values.bookingNumber, values.applicantNumber, values.mrn).subscribe({
                next: (loginResponse: ILoginResponse) => {
                    if (this.returnUrl) {
                        void this.router.navigateByUrl(this.returnUrl);
                    } else {
                        void this.router.navigate([this.authConfig.paths.homePath]);
                    }
                },
                error: (errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 418) {
                        if (this.config.messageOverrides.userNamePasswordFailure) {
                            this.notificationsService.error(this.config.messageOverrides.userNamePasswordFailure);
                        } else {
                            this.notificationsService.error(LoginConfig.userNamePasswordFailure);
                        }

                        return undefined;
                    } else if (errorResponse.status === 403) {
                        if (errorResponse.error && typeof errorResponse.error === 'string') {
                            // if error message (i.e. "account is locked") then display it
                            this.notificationsService.error(errorResponse.error);
                            return undefined;
                        } else {
                            // else rethrow the error
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            return throwError(() => new Error(errorResponse.error));
                        }
                    } else if (errorResponse.status === 400) {
                        if (errorResponse.error) {
                            if (typeof errorResponse.error === 'string') {
                                this.notificationsService.error(errorResponse.error);
                            } else if (typeof errorResponse.error?.ModelState?.Service === 'string') {
                                this.notificationsService.error(errorResponse.error.ModelState.Service as string);
                            } else {
                                this.globalErrorHandler.handleError(errorResponse);
                            }
                        }
                        return undefined;
                    } else {
                        return undefined;
                    }
                },
            });
        } else {
            markAllFormFieldsAsTouched(this.backdoorLoginForm);
        }
    }
    isTheControlInvalid(controlName: string, errorType: string): boolean {
        if (errorType === 'atLeastOneRequired') {
            return (
                this.backdoorLoginForm?.hasError('atLeastOneRequired') &&
                (this.backdoorLoginForm?.get('bookingNumber')?.touched || this.backdoorLoginForm?.get('applicantNumber')?.touched)
            );
        }
        const control = this.backdoorLoginForm?.get(controlName);
        return (control?.touched && control.hasError(errorType)) ?? false;
    }

    Login(): void {
        try {
            this.onLogin();
        } catch (error) {
            this.handleLoginError(error);
        }
    }

    handleLoginError(error: any): void {
        if (error.status === 401) {
            this.errorMessage = 'Invalid username or password.';
        } else if (error.status === 403) {
            this.errorMessage = 'Your account has been locked. Please try again later.';
        } else if (error.status === 500) {
            this.errorMessage = 'A server error occurred. Please try again later.';
        } else {
            this.errorMessage = 'An unknown error occurred. Please try again.';
        }
        this.notificationsService.error(this.errorMessage);
    }
}
